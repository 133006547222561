import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <>
    <h1>404</h1>
    <p>Looks like this page hasn&#39;t been created yet.</p>
  </>
)

export default NotFoundPage
